<template>
  <v-container>
    <v-dialog v-model="dialog" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }} </span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                xs12
                sm12
                md12
                d-flex
              >
                <v-select
                  v-model="selectedReservation"
                  :items="reservations"
                  item-text="selectText"
                  item-value="selectedReservation"
                  return-object
                  v-on:change="chooseReservation"
                  label="Reservations"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

      </v-card>
    </v-dialog>

    <v-timeline align-top>
      <v-timeline-item
        v-for="(item, i) in timelines"
        :color="item.color"
        :icon="item.icon"
        :key="i"
        :left="item.left"
        :right="item.right"
        v-if="!item.hide"
        fill-dot
      >
        <v-card dark :color="item.color">
          
          <v-card-title class="title">{{$t(item.titleId)}}</v-card-title>


          <v-container class="white text--primary">
            <v-layout>
              <v-flex xs10 class="text-sm-left">{{$t(item.textId)}}
              </v-flex>
              <v-flex xs2>
                <v-btn icon @click="reverseShowHide" v-if="item.button">
                  <v-icon x-large color="primary">notifications</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { CallDialog, Exceptions } from '@/utils'
import store from '@/store'

export default {
  data() {
    return {
      dialog: false,
      selectedReservation: null,
      timelines: [
        {
          color: 'green lighten-1',
          icon: 'cloud_upload',
          textId: 'about.timeline_add_reservation',
          titleId: 'about.timeline_host_title',
          left: false,
          right: true,
          button: false
        },
        {
          color: 'primary',
          icon: 'contact_mail',
          textId: 'about.timeline_upload_id',
          titleId: 'about.timeline_guest_title_remote',
          left: true,
          right: false,
          button: false
        },
        {
          color: 'green lighten-1',
          icon: 'verified_user',
          textId: 'about.timeline_verify_id',
          titleId: 'about.timeline_host_title',
          left: false,
          right: true,
          button: false
        },
        {
          color: 'primary',
          icon: 'directions',
          textId: 'about.timeline_guidebook',
          titleId: 'about.timeline_guest_title_remote',
          left: true,
          right: false,
          button: false
        },
        {
          color: 'primary',
          icon: 'supervisor_account',
          textId: 'about.timeline_match_id',
          titleId: 'about.timeline_guest_title_site',
          left: true,
          right: false,
          button: true
        },
        {
          color: 'green lighten-1',
          icon: 'verified_user',
          textId: 'about.timeline_host_verify',
          titleId: 'about.timeline_host_title',
          left: false,
          right: true,
          hide: true,
          button: false
        },
        {
          color: 'primary',
          icon: 'vpn_key',
          textId: 'about.timeline_key',
          titleId: 'about.timeline_guest_title_site',
          left: true,
          right: false,
          button: false
        },
      ]
    }
  },
  
  created () {
    if (!this.reservation) {
      if (this.reservations.length === 0) {
        return logout()
      } else {
        this.dialog = true
        return
      }
    }
  },

  // async beforeRouteEnter (to, from, next) {
  //   const error = await store.dispatch('checkIn/loadReservations').catch(error => {
  //     console.error(error)
  //     return error
  //   })

  //   if (error) {
  //     CallDialog(error)
  //     return next(false)
  //   } else {
  //     if (store.state.checkIn.reservations.length > 0) {
  //       return next()
  //     } else {
  //       CallDialog(Exceptions.CustomNoReservation)
  //       return next(false)
  //     }
  //   }
  // },
  // async beforeRouteUpdate (to, from, next) {
  //   const error = await store.dispatch('checkIn/loadReservations').catch(error => {
  //     console.error(error)
  //     return error
  //   })

  //   if (error) {
  //     CallDialog(error)
  //     return next(false)
  //   } else {
  //     if (store.state.checkIn.reservations.length > 0) {
  //       return next()
  //     } else {
  //       CallDialog(Exceptions.CustomNoReservation)
  //       return next(false)
  //     }
  //   }
  // },
  computed: {
    formTitle () {
      return 'Select A Reservation'
    },
    ...mapState({
      reservations: state => state.checkIn.reservations,
      reservation: state => state.checkIn.reservation,
    }),
  },
  methods: {
    reverseShowHide () {
      this.timelines[5].hide = !this.timelines[5].hide
    },
    async chooseReservation(selectedReservation) {
      console.log('selectedReservation', selectedReservation)

      await this.pickReservation(selectedReservation)

      this.dialog = false
      
    },
    ...mapActions({
      // loadReservations: 'checkIn/loadReservations',
      pickReservation: 'checkIn/pickReservation',
      logout: 'session/logout',
    })
  },
}
</script>

<style>

</style>
